import {
  ExportRemittancesParams,
  ExportRemittanceWorksParams,
  FileExtensionReference,
  GetLastRemittancesParams,
  PaginationDirectives,
  ProviderType,
  RemittancesOverviewResponse,
  RemittanceWithTotalQuantities,
  VoucherStatus,
} from '@kaa/api/providers';
import { useAsyncCallback, useLuxon } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  AlertType,
  getAlertPropsByType,
  SwAlert,
  SwColumn,
  SwContainer,
  SwFetchErrorMessage,
  SwGrid,
  SwLink,
  SwLoader,
  SwModal,
  SwPaginator,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { FormikActions } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadButtons } from '../../../components';
import { Modals } from '../../../constants';
import { getQueryParams, QueryParams, Routes } from '../../../routes';
import { useApi, useSelectedProviderState } from '../../../utils';
import {
  EventAction,
  EventCategory,
  EventLabel,
  openModalWithPageView,
  sendCustomInteractionToGTM,
  sendSearchToGTM,
} from '../../../utils/google-analytics';
import { RemittancesSearchHistoryDetailModal } from './components/detail-modal/RemittancesSearchHistoryDetailModal';
import { RemittancesReimbursementDownloadModal } from './components/download-modal/RemittancesReimbursementDownloadModal';
import { RemittancesSearchHistoryDownloadModal } from './components/download-modal/RemittancesSearchHistoryDownloadModal';
import { RemittancesSearchHistoryIncorrectModal } from './components/incorrect-modal/RemittancesSearchHistoryIncorrectModal';
import { RemittancesSearchHistoryRefusedModal } from './components/refused-modal/RemittancesSearchHistoryRefusedModal';
import { RemittancesSearchHistoryForm } from './components/RemittancesSearchHistoryForm';
import { RemittancesSearchHistoryTable } from './components/RemittancesSearchHistoryTable';
import {
  RemittancesSearchHistoryFormType,
  TODAY_DATE,
} from './RemittancesSearchHistory.constants';

const PAGE_SIZE = 30;

type RemittancesSearchHistoryProps = {
  overview: RemittancesOverviewResponse['data'];
  overviewLoading: boolean;
  getOverview: () => void;
};

export const RemittancesSearchHistory = ({
  overview,
  overviewLoading,
  getOverview,
}: RemittancesSearchHistoryProps) => {
  const { t } = useTranslation();
  const { providers } = useApi();
  const provider = useSelectedProviderState();
  const { DateTime } = useLuxon();

  const isSubsidiary = provider.type === ProviderType.SUBSIDIARY;

  const initialParameters = {
    startDate: DateTime.fromJSDate(TODAY_DATE)
      .minus({
        months: 3,
      })
      .toISODate(),
    endDate: DateTime.fromJSDate(TODAY_DATE).toISODate(),
    onAllRelatives: !isSubsidiary,
  };

  const voucherStatus = getQueryParams<VoucherStatus>(QueryParams.STATUS);
  const voucherDateFilterMonths = getQueryParams<string>(QueryParams.MONTHS);

  const [parameters, setParameters] = useState<GetLastRemittancesParams>({
    ...initialParameters,
    ...(voucherStatus ? { voucherStatus } : undefined),
    ...(voucherDateFilterMonths
      ? {
          startDate: DateTime.fromJSDate(TODAY_DATE)
            .minus({
              months: parseInt(voucherDateFilterMonths, 10),
            })
            .toISODate(),
        }
      : undefined),
  });

  const [pagination, setPagination] = useState<
    PaginationDirectives['paginationDirectives']
  >();

  const [remittances, setRemittances] = useState<
    RemittanceWithTotalQuantities[]
  >([]);

  const [currentRemittance, setCurrentRemittance] = useState<
    RemittanceWithTotalQuantities
  >();

  const [currentFileExtension, setCurrentFileExtension] = useState<
    FileExtensionReference | undefined
  >();

  const [currentRelativeId, setCurrentRelativeId] = useState<
    string | number | undefined
  >(undefined);

  const [
    {
      value: remittancesResponse,
      loading: remittancesLoading,
      error: remittancesError,
    },
    getRemittances,
  ] = useAsyncCallback(
    async (relativeId: number, remittancesParams: GetLastRemittancesParams) =>
      (await providers.getLastRemittances(relativeId, remittancesParams)).data,
    [providers],
    { loading: true },
  );

  const [
    {
      value: relativesResponse,
      loading: relativesloading,
      error: relativesError,
    },
    getRelatives,
  ] = useAsyncCallback(
    async () => {
      const {
        data: {
          data: { headquarter, subsidiaries },
        },
      } = await providers.getProviderRelatives(provider.id);

      return [headquarter, ...subsidiaries.filter(({ isActive }) => isActive)];
    },
    [providers],
    { loading: true },
  );

  const [, handleSubmit] = useAsyncCallback(
    async (
      formikData: RemittancesSearchHistoryFormType,
      formikActions: FormikActions<RemittancesSearchHistoryFormType>,
    ) => {
      const { setSubmitting, resetForm } = formikActions;
      const { searchedProviderId, ...remittancesParams } = formikData;

      setCurrentRelativeId(searchedProviderId);

      const updateParameters = {
        ...remittancesParams,
        onAllRelatives: !isSubsidiary && !searchedProviderId,
        pageSize: PAGE_SIZE,
        pageNumber: 1,
      };

      setParameters(updateParameters);
      setSubmitting(false);
      resetForm(formikData);

      sendSearchToGTM(Routes.REMITTANCES, JSON.stringify(updateParameters));

      return {
        formikActions,
        remittancesParams,
      };
    },
    [setParameters],
  );

  useEffect(() => {
    getRelatives();
  }, [getRelatives]);

  useEffect(() => {
    getRemittances(Number(currentRelativeId || provider.id), parameters);
  }, [getRemittances, parameters]);

  /* Required to force reload after update from both electronic & paper cards */
  useEffect(() => {
    if (overviewLoading) {
      getRemittances(Number(currentRelativeId || provider.id), parameters);
    }
  }, [overviewLoading]);

  useEffect(() => {
    if (remittancesResponse) {
      const { paginationDirectives, data } = remittancesResponse;

      setRemittances(data);
      setPagination(paginationDirectives);
    }
  }, [remittancesResponse]);

  if (
    (remittancesLoading && !remittancesResponse) ||
    (relativesloading && !relativesResponse)
  ) {
    return (
      <SwColumn>
        <SwContainer loading />
      </SwColumn>
    );
  }

  if (
    relativesError ||
    remittancesError ||
    !relativesResponse ||
    !remittancesResponse
  ) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage
          onClick={() => {
            getRemittances(
              Number(currentRelativeId || provider.id),
              parameters,
            );
            getRelatives();
          }}
        />
      </SwContainer>
    );
  }

  const handleReset = () => {
    setParameters(initialParameters);
    setCurrentRelativeId(undefined);
  };

  const openRemittanceModal = (
    remittance: RemittanceWithTotalQuantities,
    modalId: Modals,
    fileExtension?: FileExtensionReference,
  ) => {
    setCurrentRemittance(remittance);
    setCurrentFileExtension(fileExtension);
    openModalWithPageView(modalId);
  };

  const closeRemittanceModal = () => {
    setCurrentRemittance(undefined);
    setCurrentFileExtension(undefined);
  };

  const { incorrectVouchers } = overview;

  const downloadExtensions = [
    FileExtensionReference.XLSX,
    FileExtensionReference.CSV,
  ];

  return (
    <SwColumn width="12" widthS="12">
      <SwTitle tagName="h2">{t(i18nKeys.remittances.search.title)}</SwTitle>
      <SwGrid>
        {!overviewLoading && incorrectVouchers > 0 && (
          <SwColumn className="vl-u-spacer">
            <SwAlert
              {...getAlertPropsByType(AlertType.ERROR)}
              title={t(i18nKeys.remittances.modal.incorrect.alert.title, {
                count: incorrectVouchers,
              })}
            >
              <SwLink
                tagName="button"
                onClick={() => {
                  setParameters(() => ({
                    onAllRelatives: !isSubsidiary && !currentRelativeId,
                    voucherStatus: VoucherStatus.INCORRECT,
                    startDate: DateTime.fromJSDate(TODAY_DATE)
                      .minus({ months: 12 })
                      .toISODate(),
                    endDate: DateTime.fromJSDate(TODAY_DATE).toISODate(),
                    pageSize: PAGE_SIZE,
                    pageNumber: 1,
                  }));
                  sendCustomInteractionToGTM(
                    EventCategory.REMITTANCES,
                    EventAction.CLICK_ALERT,
                    EventLabel.REMITTANCES_SEARCH_ALERT,
                  );
                }}
              >
                {t(i18nKeys.remittances.modal.incorrect.alert.cta)}
              </SwLink>
            </SwAlert>
          </SwColumn>
        )}
        <SwColumn>
          <RemittancesSearchHistoryForm
            isSubsidiary={isSubsidiary}
            relatives={relativesResponse}
            parameters={parameters}
            relativeId={currentRelativeId}
            onSubmit={handleSubmit}
            onReset={handleReset}
          />
        </SwColumn>
        <SwColumn>
          {!remittancesLoading ? (
            <>
              <RemittancesSearchHistoryTable
                openRemittanceModal={openRemittanceModal}
                remittances={remittances}
              />
              {remittances.length && (
                <div className="vl-u-display-flex">
                  <DownloadButtons
                    modalId={Modals.REMITTANCES_SEARCH_HISTORY_DOWNLOAD_MODAL}
                    extensions={downloadExtensions}
                  />
                  {!!pagination && (
                    <div style={{ marginLeft: 'auto' }}>
                      <SwPaginator
                        itemCount={pagination.numberOfItems}
                        pageSize={PAGE_SIZE}
                        selectedPage={pagination.pageNumber}
                        setPage={(page) => {
                          setParameters((oldParameters) => ({
                            ...oldParameters,
                            pageNumber:
                              typeof page === 'number'
                                ? page
                                : page(oldParameters?.pageNumber || 1),
                          }));
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <div>
              <SwLoader />
            </div>
          )}
        </SwColumn>
      </SwGrid>
      {!!remittances.length && (
        <>
          {downloadExtensions.map((fileExtension: FileExtensionReference) => (
            <SwModal
              key={`${Modals.REMITTANCES_SEARCH_HISTORY_DOWNLOAD_MODAL}-${fileExtension}`}
              id={`${Modals.REMITTANCES_SEARCH_HISTORY_DOWNLOAD_MODAL}-${fileExtension}`}
              component={RemittancesSearchHistoryDownloadModal}
              providerId={Number(currentRelativeId || provider.id)}
              parameters={
                {
                  ...parameters,
                  fileExtension,
                } as ExportRemittancesParams
              }
              closable
            />
          ))}
        </>
      )}
      {!!currentRemittance && (
        <>
          <SwModal
            id={Modals.REMITTANCES_SEARCH_HISTORY_DETAIL_MODAL}
            component={RemittancesSearchHistoryDetailModal}
            confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
            openRemittanceModal={openRemittanceModal}
            remittance={currentRemittance}
            onClose={closeRemittanceModal}
            modFullScreen
            closable
          />
          <SwModal
            id={Modals.REMITTANCES_SEARCH_HISTORY_REFUSED_MODAL}
            component={RemittancesSearchHistoryRefusedModal}
            confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
            remittance={currentRemittance}
            onClose={closeRemittanceModal}
            modLarge
            closable
          />
          <SwModal
            id={Modals.REMITTANCES_SEARCH_HISTORY_INCORRECT_MODAL}
            component={RemittancesSearchHistoryIncorrectModal}
            confirmCloseMessage={t(i18nKeys.dialog.confirmClose.message)}
            remittance={currentRemittance}
            onClose={() => {
              getOverview();
              closeRemittanceModal();
            }}
            modFullScreen
            closable
          />
          {currentFileExtension && (
            <SwModal
              id={Modals.REMITTANCES_REIMBURSEMENT_DOWNLOAD_MODAL}
              component={RemittancesReimbursementDownloadModal}
              remittance={currentRemittance}
              onClose={closeRemittanceModal}
              parameters={
                {
                  fileExtension: currentFileExtension,
                } as ExportRemittanceWorksParams
              }
              closable
            />
          )}
        </>
      )}
    </SwColumn>
  );
};
