import get from 'lodash.get';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldPropsClean } from '../../utilities';

export const useFormError = ({
  field,
  form: { errors, touched },
}: FieldPropsClean) => {
  const { t } = useTranslation();
  return useMemo<string>(() => {
    if (
      !errors[field.name] ||
      (!get(touched, field.name) && !errors[field.name].displayFocussed)
    ) {
      return '';
    }

    const values =
      typeof errors[field.name].values === 'function'
        ? errors[field.name].values(t)
        : errors[field.name].values;

    if (typeof errors[field.name].id === 'string') {
      return t(errors[field.name].id, values);
    }

    if (typeof errors[field.name].id === 'function') {
      return errors[field.name].id(t, values);
    }

    return errors[field.name].defaultMessage;
  }, [errors, touched, field.name]);
};
